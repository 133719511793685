<template>
  <section>
    <b-col>
      <b-card class="mb-2">
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="$router.push({ name: 'bank-create' })"
          class="d-flex align-items-center">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Nuevo</span>
        </b-button>
        <b-button
          variant="primary"
          @click="showModal = !showModal"
          class="d-flex align-items-center m-1">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Agregar tasa</span>
        </b-button>
      </div>
      </b-card>
      <h3>Tasa del día {{ item.now }} </h3> 
      <h4>{{ $store.state.bank.data.amount | currency({ symbol: '$', precision: 2 })}}</h4>
      <b-row>
        <b-col v-for="(i, x) in item.banks" :key="x">
          <b-card class="mb-2" style="height: 220px;">
          <a @click="$router.push({ name: 'bank-edit', params: { id: i.id } })">Editar</a>
            <h2>{{ i.name }} </h2>
            <p v-if="i.account">
              {{ i.account }} 
            </p>
       
            <p>
              {{ i.type }} 
            </p>

            <p>
              {{ i.id_card }} 
            </p>

            <p>
              {{ i.phone }} 
            </p>

            <p>
              {{ i.status == 1 ? 'Activo' : 'Inactivo' }} 
            </p>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-modal v-model="showModal" hide-footer centered title="Agregar tasa del Dolar" no-close-on-backdrop>
        <b-overlay
            :show="loading"
            rounded
            variant="transparent"
            opacity="0.33"
            blur="2px">
            <b-form @submit.prevent="store()">
              <b-form-group label="Precio">
                <validation-provider
                  #default="{ errors }"
                  name="precio"
                  rules="required">
                  <b-form-input v-model="formData.rate"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>

              <b-form-group label="Agregar fecha de tasa pasada">
               <validation-provider
                  #default="{ errors }"
                  name="defecto"
                  rules="required">
                  <b-form-checkbox
                    v-model="showDate"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group label="Fecha" v-if="showDate">
              <validation-provider
                #default="{ errors }"
                name="fecha"
                rules="required">
                <date-picker 
                  v-model="formData.date" 
                  type="date" 
                  :default-value="new Date()" 
                  :disabled-date="disabledBeforeToday"
                  ></date-picker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <footer class="modal-footer p-0">
              <b-button variant="outline-primary modal-btn" @click="close">
                  Cancelar
              </b-button>
              <b-button type="submit" variant="primary">
                  Guardar
              </b-button>
            </footer>
          </b-form>
          </b-overlay>
        </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BButton,
  BLink,
  VBModal,
  BFormInput,
  BFormGroup,
  BOverlay,
  BForm,
  BInputGroup,
  BFormCheckbox
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
localize('es', es)
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BLink,
    VBModal,
    BFormInput,
    BFormGroup,
    BOverlay,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormCheckbox,
    DatePicker
  },
  data() {
    return {
      showDate: false,
      required,
      loading: false,
      item: {
        rate: {}
      },
      showModal: false,
      formData: {
        rate: ''
      }
    }
  },
  methods: {
    disabledBeforeToday(date) {
      const today = new Date();
      return date >= new Date(today.getTime()) 
    },
    async getItems() { 
      try {
        this.loading = true
        this.item = await this.$store.dispatch('bank/index')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async store() {
      await this.$store.dispatch('bank/storeRate', this.formData)
      .then(() => {
        this.close()
        this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Éxito',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Tasa realizada con éxito',
        }})
      })
    },
    close(){
      this.showModal = false
      this.formData.rate = null
      this.formData.date = null
      this.showDate = false
    },
  },
  async mounted() {
    this.getItems()
  },
}
</script>

<style>
.mx-datepicker {
    width: 100%;
  }
</style>